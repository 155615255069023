<template>
  <div class="app-container common-list-page">
    <el-form
      :model="resetForm"
      :rules="resetFormRules"
      ref="resetForm"
      status-icon
      label-width="100px"
    >
      <el-form-item label="旧密码：" prop="password">
        <el-input
          type="password"
          v-model="resetForm.password"
          auto-complete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="新密码：" prop="newpwd">
        <el-input
          type="password"
          v-model="resetForm.newpwd"
          auto-complete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="确认密码：" prop="newpassword1">
        <el-input
          type="password"
          v-model="resetForm.newpassword1"
          auto-complete="off"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="danger" @click.native.prevent="toAmend"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { changeauthorpassword } from '../../../API/ZZD/setting.js'
export default {
  data () {
    var validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入新密码"));
      } else if (value.toString().length < 6 || value.toString().length > 18) {
        callback(new Error("密码长度为6-18位"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.resetForm.newpwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      time: '',//定时清除
      resetForm: {
        //传给后台所需要的参数
        newpassword1: "",
        password: "",
        username: ""//此处只是后台需要的字段而已，如果前期有公用cookie里面有获取并且保存过，现在需要另外调用进来，具体的获取方法就看个人了
      },
      resetFormRules: {
        password: [
          { required: true, message: "请输入旧密码", trigger: 'blur' }
        ],
        newpwd: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        newpassword1: [
          { required: true, validator: validatePass2, trigger: "blur" }
        ]
      }
    };
  },
  methods: {

    toAmend () {
      this.$refs.resetForm.validate(valid => {
        if (valid) {
          let id = localStorage.getItem('ZZId')
          changeauthorpassword(id, this.resetForm.newpassword1, this.resetForm.password,)
            .then(res => {
              console.log(res)
              if (res.data.code == 0) {
                this.$message({
                  type: 'info',
                  message: '修改密码成功,1秒后重新登录',
                  type: 'success'
                });
                this.time = setTimeout(() => {
                  this.$router.push("/login");
                }, 1000)
                this.$emit('back')
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                  duration: 500,

                });
              }
              ic
            })
            .catch(() => { });
        }
      });
    },
  },
  beforeDestroy () {
    clearTimeout(this.timer);
  }
};
</script>

<style  scoped>
.el-form {
  width: 40%;
}
button {
  margin: 20px 0 0;
}
</style>