<template>
  <div>
    <el-form
      label-width="80px"
      :model="ruleForms"
      :rules="rules"
      ref="ruleForms"
    >
      <el-form-item label="头像">
        <label for="headImg">
          <img
            class="photo"
            :src="ruleForms.headImgUrl"
            alt
            style="cursor: pointer"
          />
        </label>
        <input
          class="file"
          style="display: none"
          id="headImg"
          name="multipartFile"
          type="file"
          @change="up1"
        />
      </el-form-item>
      <el-form-item label="昵称">
        <el-input
          v-model="ruleForms.editor"
          placeholder="请输入昵称"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="性别" style="width: 315px" prop="sex">
        <el-radio-group v-model="ruleForms.sex" @change="sexs">
          <el-radio label="男">男</el-radio>
          <el-radio label="女">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <div style="width: 300px">
          <el-input
            type="text"
            maxlength="11"
            placeholder="请输入手机号"
            v-model="ruleForms.phone"
            class="addInput"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="账号">
        <el-input
          v-model="ruleForms.loginname"
          placeholder="请输入账号"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="密码">
        <el-input
          type="text"
          v-model="ruleForms.password"
          placeholder="请输入密码"
          style="width: 300px"
          readonly
        ></el-input>
      </el-form-item>
      <el-form-item label="学历">
        <el-select
          class="addInput"
          v-model="ruleForms.education"
          style="width: 300px"
          clearable
        >
          <el-option
            v-for="item in departments"
            :key="item.name"
            :label="item.name"
            :value="item.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="单位">
        <el-input
          v-model="ruleForms.unit"
          placeholder="请输入单位地址"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="职称">
        <el-input
          v-model="ruleForms.professional"
          placeholder="请输入职称"
          style="width: 300px"
        ></el-input>
        
        <!-- <el-select
          class="addInput"
          clearable
          placeholder="请选择职称"
          style="width: 145px; margin-right: 10px"
          v-model="zc1"
          @change="selectfirstzc"
        >
          <el-option
            v-for="item in departmentList"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          ></el-option>
        </el-select>
        <el-select
          class="addInput"
          clearable
          placeholder="请选择职称"
          v-model="zc2"
          style="width: 145px"
          @change="selectsecondzc"
        >
          <el-option
            v-for="item in departmentList1"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          ></el-option>
        </el-select> -->
      </el-form-item>
      <el-form-item label="专业">
        <el-input
          v-model="ruleForms.major"
          placeholder="请输入专业"
          style="width: 300px"
        ></el-input>
        <!--
        <el-select
          class="addInput"
          clearable
          placeholder="请选择专业"
          v-model="zy1"
          style="width: 145px; margin-right: 10px"
          @change="selectfirstzy"
        >
          <el-option
            v-for="item in majoroption"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          ></el-option>
        </el-select>
        <el-select
          class="addInput"
          clearable
          placeholder="请选择专业"
          v-model="zy2"
          style="width: 145px; margin-right: 10px"
          @change="selectsecondzy"
        >
          <el-option
            v-for="item in majoroption2"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          ></el-option>
        </el-select>
        <el-select
          class="addInput"
          clearable
          placeholder="请选择专业"
          v-model="zy3"
          style="width: 145px"
          @change="selectthirdzy"
        >
          <el-option
            v-for="item in majoroption3"
            :key="item.Id"
            :label="item.Name"
            :value="item.Id"
          ></el-option>
        </el-select>-->
      </el-form-item>
      <el-form-item label="邮寄地址">
        <v-distpicker
          hide-area
          @selected="showregin"
          :province="province"
          :city="city"
        ></v-distpicker>
      </el-form-item>
      <el-form-item label="详细地址">
        <el-input
          placeholder="请输入详细地址"
          v-model="ruleForms.detailAddress"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="简介">
        <el-input
          style="width: 455px"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
          placeholder="请输入内容"
          v-model="ruleForms.textarea"
        >
        </el-input>
      </el-form-item>

      <div style="margin: 0px 0px 42px 60px">
        <el-button
          type="danger"
          @click="userAddTap('ruleForms')"
          style="width: 100px; background: red; border: red"
          >保存</el-button
        >
        <el-button
          v-if="user_state != 0 && user_state != 1"
          type="danger"
          @click="fuwushangUp('ruleForms')"
          style="width: 100px; background: red; border: red"
          >升级服务商</el-button
        >
        <el-button
          v-if="user_state == 0"
          :disabled="true"
          type="danger"
          style="width: 160px; background: red; border: red"
          >服务商审核中</el-button
        >
        <div v-if="user_state == 2" style="margin-top: 10px; margin-right: 10px">
          <el-alert title="服务商审核已驳回" type="error" effect="dark">
          </el-alert>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { automatically } from "../../../API/ZZD/storage";
import { ShowProfessional, ShowMajor } from "../../../API/ZZD/setting.js";
import addImg from "../../../assets/image/upImg.jpg";
import VDistpicker from "v-distpicker";
export default {
  components: {
    VDistpicker,
  },
  data() {
    //验证手机号的规则;
    var checkMobile = (rule, value, cb) => {
      // 验证手机号的正则表达式
      const regMobile = /^1[0-9]{10}$/;
      if (regMobile.test(value)) {
        return cb();
      }
      cb(new Error("请输入合法的手机号"));
    };
    return {
      ruleForms: {
        loginname: "",
        headImg: "", // 头像
        headImgUrl: require("../../../assets/image/ygtx.jpg"), // 回显地址
        realname: "",
        editor: "", // 作者昵称
        professional: "", //职称
        education: "", //学历
        sex: "男",
        postAddress: "", //地址
        detailAddress: "", // 详细地址
        unit: "", // 单位
        imageUrl: "", //头像url
        phone: "", // 手机号
        time: "", // 入职时间
        textarea: "", // 备注
        id: "", //作者id
        major: "", // 专业
        password: "", //密码
      },
      rules: {
        loginname: [{ required: true, message: "请输入账号", trigger: "blur" }],
        realname: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
        time: [{ required: true, message: "请选择入职日期", trigger: "blur" }],
        time: [{ required: true, message: "请选择日期", trigger: "blur" }],
        phone: [
          { required: true, message: "手机号码不能为空" },
          {
            validator: checkMobile,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
      province: "",
      city: "",

      departments: [
        { name: "博士" },
        { name: "硕士" },
        { name: "研究生" },
        { name: "本科" },
        { name: "专科" },
        { name: "高中以下" },
      ], // 学历

      departmentList: [
        {
          Pid: 150000,
          Id: 150100,
          Name: "一级公证员",
        },
      ], // 职称
      departmentList1: [], // 职称
      majoroption: [], //专业
      majoroption2: [], //专业
      majoroption3: [], //专业
      zc1: "",
      zc2: "",
      zy1: "",
      zy2: "",
      zy3: "",
      user_state: "", //0为待审批，1为审批通过,2为已驳回，其余为未申请
    };
  },

  mounted() {
    this.initData();
    this.myShowProfessional();
    this.myShowMajor();
  },
  methods: {
    showregin(e) {
      this.ruleForms.postAddress = e.province.value + "," + e.city.value;
    },
    up1(e) {
      //头像
      this.ruleForms.headImg = e.target.files[0]; //图片文件
      this.ruleForms.headImgUrl = URL.createObjectURL(e.target.files[0]); //图片路径回显
    },
    FanHui() {
      this.$router.push("/Services/EmPloyees/?id=1");
    },
    // 查询职称
    myShowProfessional() {
      ShowProfessional(0).then((res) => {
        this.departmentList = res.data.data;
        console.log(this.departmentList);
      });
    },
    // 查询专业
    myShowMajor() {
      ShowMajor(0).then((res) => {
        this.majoroption = res.data.data;
        console.log(this.majoroption);
      });
    },
    selectfirstzc(e) {
      ShowProfessional(e).then((res) => {
        this.departmentList1 = res.data.data;
      });
    },
    selectsecondzc(e) {
        this.ruleForms.professional = [this.zc1,this.zc2]
    },
    selectfirstzy(e) {
      ShowMajor(e).then((res) => {
        this.majoroption2 = res.data.data;
      });
    },
    selectsecondzy(e) {
      ShowMajor(e).then((res) => {
        this.majoroption3 = res.data.data;
      });
    },
    selectthirdzy(e) {},

    // 回显个人信息
    initData() {
      let id = localStorage.getItem("ZZId");

      automatically(id).then((res) => {
        if (res.data.data.headimg.length != 0) {
          this.ruleForms.headImgUrl = this.URL + res.data.data.headimg;
        }
        this.ruleForms.sex = res.data.data.gender;
        this.ruleForms.loginname = res.data.data.loginname;
        this.ruleForms.phone = res.data.data.phone;
        this.ruleForms.editor = res.data.data.editor;
        this.ruleForms.education = res.data.data.education;
        this.ruleForms.unit = res.data.data.unit;
        this.ruleForms.detailAddress = res.data.data.detailAddress;
        this.ruleForms.textarea = res.data.data.introduction;
        this.ruleForms.id = res.data.data.id;
        this.ruleForms.password = res.data.data.password;
        const address = res.data.data.postAddress.split(",");
        this.province = address[0]; // 省
        this.city = address[1]; // 市
        this.ruleForms.professional = res.data.data.professional.split("/");
        this.ruleForms.major = res.data.data.major.split("/");
        this.zc1 = Number(this.ruleForms.professional[0]);
        this.zc2 = Number(this.ruleForms.professional[1]);
        this.selectfirstzc(this.zc1);
        this.selectsecondzc(this.zc2);
        this.zy1 = Number(this.ruleForms.major[0]);
        this.zy2 = Number(this.ruleForms.major[1]);
        this.zy3 = Number(this.ruleForms.major[2]);
        // state 1为待审批，2为已驳回  无为没有申请过
        this.user_state = res.data.data.state;
        console.log("user_state", this.user_state);
        this.selectfirstzy(this.zy1);
        this.selectsecondzy(this.zy2);
        this.selectthirdzy(this.zy3);
      });
    },
    //添加员工
    userAddTap(ruleForms) {
      this.$refs[ruleForms].validate((valid, obj) => {
        if (valid) {
          console.log(this.ruleForms.headImg);
          var formdata = new FormData();
          formdata.append("id", this.ruleForms.id);
          formdata.append("headImg", this.ruleForms.headImg);
          formdata.append("loginname", this.ruleForms.loginname);
          formdata.append("editor", this.ruleForms.editor);
          formdata.append("gender", this.ruleForms.sex);
          formdata.append("unit", this.ruleForms.unit);
          // formdata.append('professionalOne', this.zc1);
          // formdata.append('professionalTwo', this.zc2);
          formdata.append("professional", this.ruleForms.professional);
          formdata.append("education", this.ruleForms.education);
          // formdata.append('majorOne', this.zy1);
          // formdata.append('majorTwo', this.zy2);
          // formdata.append('majorThree', this.zy3);
          formdata.append("major", this.ruleForms.major);
          formdata.append("postAddress", this.ruleForms.postAddress);
          formdata.append("detailAddress", this.ruleForms.detailAddress);
          formdata.append("introduction", this.ruleForms.textarea);
          formdata.append("password", this.ruleForms.password);
          //添加
          this.$axios({
            method: "post",
            url: "/author/modifyauthor",
            data: formdata,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then((res) => {
            if (res.data.code == 0) {
              this.$message({
                type: "info",
                message: "保存成功",
                type: "success",
              });
              this.initData();
              this.$root.$emit("next"); //调用父路由中监听的next方法,然后传两个参
            } else {
              this.$message(res.data.msg);
            }
          });
        }
      });
    },
    //作者升级服务商
    fuwushangUp(ruleForms) {
      this.$refs[ruleForms].validate((valid, obj) => {
        if (valid) {
          // console.log(this.ruleForms.headImg);
          var formdata = new FormData();
          formdata.append("id", this.ruleForms.id);
          formdata.append("phone", this.ruleForms.phone);

          this.$axios({
            method: "post",
            url: "/other/oneInfo",
            data: formdata,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then((res) => {
            if (res.data.data.cc != "0") {
              this.$router.push("/Pending/countsetting/detail");
              this.$message("申请已提交，请勿重复操作");
            } else {
              //升级
              this.$axios({
                method: "post",
                url: "/other/fwsUp",
                data: formdata,
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }).then((res) => {
                console.log('升级服务商',res)
                if (res.data.code == 0) {
                  this.$router.push("/Pending/countsetting/detail");
                  this.$message(res.data.msg);
                } else {
                  // this.$router.push("/Pending/countsetting/detail");
                  this.$message(res.data.msg);
                }
              });
            }
          });
        }
      });
    },
    // 查询职级
    setRank() {
      userAddLists().then((res) => {
        this.departmentList = res.data.data;
        console.log(this.departmentList);
      });
    },
    sexs(e) {
      console.log(e);
    },
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.headerImg {
  object-fit: cover;
  object-position: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 50px 150px;
}
.headerInput {
  position: absolute;
  top: 220px;
  left: 43%;
  right: 0;
  width: 8%;
  height: 35%;
  border-radius: 50%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}
.photo {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50%;
}
</style>