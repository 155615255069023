<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="个人资料" name="first"><CountsetTings></CountsetTings></el-tab-pane>
      <el-tab-pane label="修改密码" name="second"><CountsettingPw></CountsettingPw></el-tab-pane>
    </el-tabs>
  </div>
</template>
       

<script>
import CountsetTings from './countsettings'
import CountsettingPw from './countsettingPw'

export default {
  components: {
    CountsetTings,
    CountsettingPw
  },
  data () {
    return {
      activeName: 'first'
    }

  },
  mounted () {

  },
  methods: {
    handleClick(tab, event) {
        console.log(tab, event);
      }
  }
}

</script>

<style>
</style>