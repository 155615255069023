import request from '@/utils/request'
import qs from 'qs'
// 修改密码
export function changeauthorpassword (id,password,password_old) {
  let data = {
   id,password,password_old
  }
  data = qs.stringify(data)
  return request({
    url: '/author/modifyPwd',
    method: 'post',
    data
  })
}
//职称接口
export function ShowProfessional (Pid) {
  let data = {
   Pid
  }
  data = qs.stringify(data)
  return request({
    url: '/author/ShowProfessional',
    method: 'post',
    data
  })
}
//专业接口
export function ShowMajor (Pid) {
  let data = {
  Pid
  }
  data = qs.stringify(data)
  return request({
    url: '/author/ShowMajor',
    method: 'post',
    data
  })
}